<template>
  <div class="login-page">
    <div class="login-page__window">
      <h2 class="mb-3">Авторизация</h2>
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="phone" class="mb-2"> Телефон </label>
          <input
            type="text"
            v-mask="'+7 (###) ###-##-##'"
            v-model="phone"
            required
            placeholder="+7 (999) 999-99-99"
            class="form-control"
          />
        </div>
        <div class="mb-3">
          <label for="phone" class="mb-2"> Пароль </label>
          <input
            type="password"
            required
            v-model="password"
            class="form-control"
          />
        </div>
        <button class="btn btn-primary" type="submit">Войти</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      phone: "",
      password: "",
    };
  },
  methods: {
    login: function () {
      let phone = this.phone;
      let password = this.password;
      this.$store
        .dispatch("login", { phone, password })
        .then(() => {
          this.$router.push("/");
        })
        .catch((err) => {
          this.$bvToast.toast(`Неправильное имя пользователя или пароль`, {
            title: "Ошибка",
            autoHideDelay: 3000,
            variant: "danger",
            solid: true,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  position: relative;
  width: 100%;
  height: 100vh;

  &__window {
    position: absolute;
    max-width: 500px;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
